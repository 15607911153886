import React, { useEffect } from 'react'
import { currentVersion } from '../utils/constants'

const VersionManager = ({ children }) => {
  useEffect(() => {
    const localStorageVersion = localStorage.getItem('version')
    if (localStorageVersion) {
      if (localStorageVersion !== currentVersion) {
        localStorage.clear()
        localStorage.setItem('version', currentVersion)
      }
    } else {
      if (currentVersion) {
        localStorage.setItem('version', currentVersion)
      }
    }
  }, [currentVersion])
  return <>{children}</>
}

export default VersionManager
